export default {
  config: { useRootStyles: true },
  breakpoints: ['760px', '1024px', '1280px'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  // col = 92, margin = 16
  // colN = (N*92) + ((N-1)*16)
  sizes: {
    col1: 92,
    col2: 200,
    col3: 308,
    col4: 416,
    col5: 524,
    col6: 632,
    col7: 740,
    col8: 848,
    col10: 1064,
    col12: 1280,
  },
  colors: {
    text: "#222222",
    tire: "#222222",
    hotRed: '#F2360D',
    msqRed: '#c52f31',
    contiYellow: '#FCB134',
    neutralGold: '#DBBC6E',
    vintageYellow: '#E4D587',
    almond: '#EEEDDC',
    aqua: '#AAD4DB',
    dipsea: '#336B79',
    hotBlue: '#417BBA',
    medBlue: '#2865a7',
    navy: '#2B3D46',
    radiatorFluid: '#C9CD4C',
  },
  fonts: {
    body: '"Noe Text", "Helvetica Neue", sans-serif',
    heading: '"Noe Display"',
    sans: "proxima-nova",
    mono: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    book: 500,
    bold: 700,
    black: 900,
  },
  lineHeights: {
    body: 1.3,
    single: 1,
    heading: 1,
  },
  text: {
    color: 'tire',
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'bold',
    },
    poster: {
      fontFamily: 'sans',
      fontWeight: 'black',
      lineHeight: 'heading',
      textTransform: 'uppercase',
    },
    caps: {
      fontFamily: 'sans',
      textTransform: 'uppercase',
    },
  },
  // fontSizes: [14, 17, 20, 24, 36, 48, 60],
  fontSizes: [14, 17, 20, 24, 30, 36, 48],
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      fontSize: 1,
    },
    h1: {
      variant: 'text.heading',
      fontSize: 6,
    },
    h2 : {
      variant: 'text.heading',
      fontSize: [4,4,5],
    },
    h3: {
      variant: 'text.heading',
      fontSize: [3,4],
    },
    h4: {
      variant: 'text.heading',
      fontSize: [2,3],
    },
    p: {
      fontFamily: 'body',
      fontSize: 1,
      lineHeight: 1.4,
    },
    a: {
      default: {
        color: 'medBlue',
        fontWeight: 500,
        textDecoration: 'underline',
        ':hover': {
          textDecoration: 'underline',
          textUnderlineOffset: '2px',
          textDecorationThickness: '2px',
        },
      },
      fatItalic: {
        color: 'hotRed',
        fontFamily: 'sans',
        fontWeight: 'black',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        textDecoration: 'none',
        fontSize: [2,2,3],
        alignSelf: 'flex-start',
        p: 2,
        '&:hover': {
          bg: 'hotRed',
          color: 'white',
        },
      },
      slant: {
        color: 'medBlue',
        fontFamily: 'sans',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        textDecoration: 'none',
        fontSize: 2,
        alignSelf: 'flex-start',
        px: 2,
        py: 1,
        '&:hover': {
          bg: 'medBlue',
          color: 'white',
        },
      },
      fat: {
        color: ['hotRed', null, null,'text'],
        fontFamily: 'sans',
        fontWeight: 'black',
        textTransform: 'uppercase',
        textDecoration: 'none',
        lineHeight: .9,
        fontSize: [2, 2, 3],
        // alignSelf: ['center', 'flex-start'],
        // textAlign: ['center', 'left'],
        p: 2,
        '&:hover': {
          bg: 'hotRed',
          color: 'white',
        },
      },
      thinWhite: {
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
          color: 'contiYellow'
        },
      },
      mobilemenu: {
        color: 'white',
        textTransform: 'uppercase',
        '&:hover': {
          color: 'contiYellow',
        },
      },
      header: {
        textTransform: "uppercase",
        fontFamily: "sans",
        fontSize: 0,
        color: "text",
        textDecoration: 'none',
        px: 3,
        py: 2,
        '&:hover': {
          textDecoration: 'underline',
          textDecorationThickness: '3px',
          textUnderlineOffset: '2px',
          textDecorationColor: 'text',
        }
      },
      card: {
        color: 'text',
        textDecoration: 'none',
        '&:hover': {
          color: '#333',
        },
      },
      press: {
        color: 'text',
        textDecoration: 'none',
        '&:hover': {
          color: 'hotRed',
        },
      },
      alertbar: {
        color: 'text',
        fontWeight: 400,
        fontFamily: 'sans',
        // fontWeight: 'extrabold',
        // textTransform: 'uppercase',
        textDecoration: 'none',
        '&:hover': {
          color: 'text',
          textDecoration: 'underline',
        },
      }
    },

  },
  buttons: {

    primary: {
      display: 'inline-block',
      fontSize: 0,
      fontWeight: 700,
      fontStyle: 'italic',
      fontFamily: 'sans',
      textTransform: 'uppercase',
      border: '3px solid',
      borderRadius: 0,
      alignSelf: 'flex-start',
      color: 'white',
      bg: 'hotRed',
      borderColor: 'hotRed',
      '&:hover': {
        bg: 'msqRed',
        color: 'white',
        borderColor: 'msqRed',
      }
    },
    secondary: {
      display: 'inline-block',
      fontSize: 0,
      fontWeight: 700,
      fontStyle: 'italic',
      fontFamily: 'sans',
      textTransform: 'uppercase',
      border: '3px solid',
      borderRadius: 0,
      alignSelf: 'flex-start',
      color: 'tire',
      bg: 'white',
      '&:hover': {
        bg: 'text',
        color: 'white',
        borderColor: 'text',
      },
    },
  },
}